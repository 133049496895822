import React from "react";

const Wallet = () => (
  <div className="row">
        <div className="col-lg-3 mb30">
            <span className="box-url">
            <span className="box-url-label">Most Popular</span>
            <img
                src="./img/services/Website Development.svg"
                alt=""
                className="mb20"
                height={"80px"}
            />
            <h4>Website Development</h4>
            <p>
                Transform your ideas into a solid business product with AtoZee web
                solutions.
            </p>
            </span>
        </div>

        <div className="col-lg-3 mb30">
        <span className="box-url">
            <img
            src="./img/services/app development.svg"
            alt=""
            className="mb20"
            height={"80px"}
            />
            <h4>App Development</h4>
            <p>
            Get an intuitive and visually pleasing custom mobile application for
            your business.
            </p>
        </span>
        </div>

        <div className="col-lg-3 mb30">
        <span className="box-url">
            <img
            src="./img/services/software development.svg"
            alt=""
            className="mb20"
            height={"80px"}
            />
            <h4>Custom Softwares</h4>
            <p>
            Empower your business with bespoke digital solutions and enhancements
            from Atozee.
            </p>
        </span>
        </div>

        <div className="col-lg-3 mb30">
        <span className="box-url">
            <span className="box-url-label">Most Popular</span>
            <img
            src="./img/services/Ad Campaign.svg"
            alt=""
            className="mb20"
            height={"80px"}
            />
            <h4>Ads Campaign</h4>
            <p>
            Boost with our Ads service. AtoZee creates campaigns, showing your
            business online.
            </p>
        </span>
        </div>

        <div className="col-lg-3 mb30">
        <span className="box-url">
            <img
            src="./img/services/Social Media.svg"
            alt=""
            className="mb20"
            height={"80px"}
            />
            <h4>Social Media Marketing</h4>
            <p>
            Use AtoZee strategies, standing out on sites and other digital
            solutions for startups to enable your business.
            </p>
        </span>
        </div>

        <div className="col-lg-3 mb30">
        <span className="box-url">
            <img
            src="./img/services/Email Marketing.svg"
            alt=""
            className="mb20"
            height={"80px"}
            />
            <h4>Email Marketing</h4>
            <p>
            Communicate through our email service. AtoZee sends cool messages to
            your customer's inbox, making a big impact.
            </p>
        </span>
        </div>

        <div className="col-lg-3 mb30">
        <span className="box-url">
            <img
            src="./img/services/SEO.svg"
            alt=""
            className="mb20"
            height={"80px"}
            />
            <h4>Search Engine Optimization (SEO)</h4>
            <p>
            Get noticed with our SEO service. AtoZee ensures showing up, bringing
            more visitors.
            </p>
        </span>
        </div>

        <div className="col-lg-3 mb30">
        <span className="box-url">
            <span className="box-url-label">Most Simple</span>
            <img
            src="./img/services/Blogging.svg"
            alt=""
            className="mb20"
            height={"80px"}
            />
            <h4>Content Writing</h4>
            <p>
            Improve content with our service. AtoZee creates material, making sure
            your brand's message is clear and engaging.
            </p>
        </span>
        </div>
  </div>
);
export default Wallet;
