import React from 'react';
import { Router, Redirect } from '@reach/router';
import { createGlobalStyle } from 'styled-components';
import WhatsAppButton from './components/WhatsAppButton';
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/header';
import Home from './pages/home';
import Website from './pages/development/website';
import MobileApp from './pages/development/mobileApp';
import CustomSoftware from './pages/development/customSoftware';
import AdsCampaign from './pages/marketing/adsCampaign';
import SocialMediaMarketing from './pages/marketing/socialMediaMarketing';
import SEO from './pages/marketing/seo';
import EmailMarketing from './pages/marketing/emailMarketing';
import ContentWriting from './pages/marketing/contentWriting';
import Contact from './pages/contact';
import About from './pages/about';

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

const App = () => (
  <div className="wraper">
    <GlobalStyles />
    <Header />
    <Router>
      <Home path="/" />
      <Website path="/website" />
      <MobileApp path="/mobileApp" />
      <CustomSoftware path="/customSoftware" />
      <AdsCampaign path="/adsCampaign" />
      <SocialMediaMarketing path="/socialMediaMarketing" />
      <SEO path="/seo" />
      <EmailMarketing path="/emailMarketing" />
      <ContentWriting path="/contentWriting" />
      <Contact path="/contact" />
      <About path="/about" />
      {/* Add more routes as needed */}
      <Redirect from="/home" to="/" noThrow />
    </Router>
    <WhatsAppButton />
    <ScrollToTopBtn />
  </div>
);

export default App;




// import React from 'react';
// import { Router, Location, Redirect } from '@reach/router';
// import ScrollToTopBtn from './menu/ScrollToTop';
// import Header from './menu/header';
// import Home from './pages/home';
// import Home1 from './pages/home1';
// import Home2 from './pages/home2';
// import Home3 from './pages/home3';
// import Explore from './pages/explore';
// import Explore2 from './pages/explore2';
// import Rangking from './pages/rangking';
// import Auction from './pages/Auction';
// import Helpcenter from './pages/helpcenter';
// import Colection from './pages/colection';
// import ItemDetail from './pages/ItemDetail';
// import Author from './pages/Author';
// import Website from './pages/development/website';
// import MobileApp from './pages/development/mobileApp';
// import CustomSoftware from './pages/development/customSoftware';
// import AdsCampaign from './pages/marketing/adsCampaign';
// import SocialMediaMarketing from './pages/marketing/socialMediaMarketing';
// import EmailMarketing from './pages/marketing/emailMarketing';
// import SEO from './pages/marketing/seo';
// import ContentWriting from './pages/marketing/contentWriting';
// import Wallet from './pages/wallet';
// import Login from './pages/login';
// import LoginTwo from './pages/loginTwo';
// import Register from './pages/register';
// import Price from './pages/price';
// import Works from './pages/works';
// import News from './pages/news';
// import Create from './pages/create';
// import Create2 from './pages/create2';
// import Create3 from './pages/create3';
// import Createoption from './pages/createOptions';
// import Activity from './pages/activity';
// import Contact from './pages/contact';
// import About from './pages/about';
// import ElegantIcons from './pages/elegantIcons';
// import EtlineIcons from './pages/etlineIcons';
// import FontAwesomeIcons from './pages/fontAwesomeIcons';
// import Accordion from './pages/accordion';
// import Alerts from './pages/alerts';
// import Progressbar from './pages/progressbar';
// import Tabs from './pages/tabs';

// import { createGlobalStyle } from 'styled-components';
// import WhatsAppButton from './components/WhatsAppButton';

// const GlobalStyles = createGlobalStyle`
//   :root {
//     scroll-behavior: unset;
//   }
// `;

// export const ScrollTop = ({ children, location }) => {
//   React.useEffect(() => window.scrollTo(0,0), [location])
//   return children
// }

// const PosedRouter = ({ children }) => (
//   <Location>
//     {({ location }) => (
//       <div id='routerhang'>
//         <div key={location.key}>
//           <Router location={location}>
//             {children}
//           </Router>
//         </div>
//       </div>
//     )}
//   </Location>
// );

// const app= () => (
//   <div className="wraper">
//   <GlobalStyles />
//     <Header/>
//       <PosedRouter>
//       <ScrollTop path="/">
//         <Home exact path="/">
//           <Redirect to="/home" />
//         </Home>
//         <Website path="/website" />
//         <MobileApp path="/mobileApp" />
//         <CustomSoftware path="/customSoftware" />
//         <AdsCampaign path="/adsCampaign" />
//         <SocialMediaMarketing path="/socialMediaMarketing" />
//         <SEO path="/seo" />
//         <EmailMarketing path="/emailMarketing" />
//         <ContentWriting path="/contentWriting" />
//         <Contact path="/contact" />
//         <About path="/about" />

        
//         <Home1 path="/home1" />
//         <Home2 path="/home2" />
//         <Home3 path="/home3" />
//         <Explore path="/explore" />
//         <Explore2 path="/explore2" />
//         <Rangking path="/rangking" />
//         <Auction path="/Auction" />
//         <Helpcenter path="/helpcenter" />
//         <Colection path="/colection" />
//         <ItemDetail path="/ItemDetail" />
//         <Author path="/Author" />
//         <Wallet path="/wallet" />
//         <Login path="/login" />
//         <LoginTwo path="/loginTwo" />
//         <Register path="/register" />
//         <Price path="/price" />
//         <Works path="/works" />
//         <News path="/news" />
//         <Create path="/create" />
//         <Create2 path="/create2" />
//         <Create3 path="/create3" />
//         <Createoption path="/createOptions" />
//         <Activity path="/activity" />
//         <ElegantIcons path="/elegantIcons" />
//         <EtlineIcons path="/etlineIcons" />
//         <FontAwesomeIcons path="/fontAwesomeIcons" />
//         <Accordion path="/accordion" />
//         <Alerts path="/alerts" />
//         <Progressbar path="/progressbar" />
//         <Tabs path="/tabs" />
//         </ScrollTop>
//       </PosedRouter>
//       <WhatsAppButton />
//     <ScrollToTopBtn />
    
//   </div>
// );
// export default app;