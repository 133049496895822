import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/animated.css";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';

import './assets/de-modern.scss';
import './assets/coloring-gradient.scss';
import './assets/colors/scheme-12.css';

import './assets/style.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
		<App />

	, document.getElementById('root'));
serviceWorker.unregister();