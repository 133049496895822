import React from "react";
import Footer from "../../components/footer";
import { createGlobalStyle } from "styled-components";
import WebsiteContent from "../../components/websiteContent";

const GlobalStyles = createGlobalStyle`
  // header#myHeader.navbar.sticky.white {
  //   background: #403f83;
  //   border-bottom: solid 1px #403f83;
  // }
  // header#myHeader.navbar .search #quick_search{
  //   color: #fff;
  //   background: rgba(255, 255, 255, .1);
  // }
  // header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
  //   color: #fff;
  // }
  // header#myHeader .dropdown-toggle::after{
  //   color: rgba(255, 255, 255, .5);
  // }
  // header#myHeader .logo .d-block{
  //   display: none !important;
  // }
  // header#myHeader .logo .d-none{
  //   display: block !important;
  // }
  // @media only screen and (max-width: 1199px) {
  //   .navbar{
  //     background: #403f83;
  //   }
  //   .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
  //     background: #fff;
  //   }
  //   .item-dropdown .dropdown a{
  //     color: #fff !important;
  //   }
  // }
`;

const website = () => (
  <div>
    <GlobalStyles />

    <section
      className="jumbotron breadcumb no-bg"
      // style={{ backgroundImage: `url(${"./img/background/5.jpg"})` }}
    >
      <video autoPlay muted loop
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
        controls={false}
      >
        <source src="./img/mp4/atozeeSols-desiging.mp4" type="video/mp4" />
        {/* Add additional source elements for different video formats if needed */}
        Your browser does not support the video tag.
      </video>
      <div className="mainbreadcumb bg-custom">
        <div className="container">
          <div className="row m-10-hor">
            <div className="col-12 ">
              <h1 className="pb-4">Bespoke Web Solutions</h1>
              <p>Transform your ideas into a solid business product with our bespoke web solutions and expertise in Fintech, Healthcare, Construction, and other industries</p>
            </div>
          </div>
        </div>
      </div>
      
      <nav aria-label="breadcrumb">
                <ol className="breadcrumb  p-2">
                  <li className="breadcrumb-item text-white">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item  text-white" aria-current="true">
                    Design & Develop
                  </li>
                  <li className="breadcrumb-item active  text-white" aria-current="true">
                    Website Development
                  </li>
                </ol>
              </nav>
    </section>

    <section className="container">
      <WebsiteContent />
    </section>

    <Footer />
  </div>
);
export default website;
