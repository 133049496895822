import React from "react";
import WebPricing from "./webPricing";

const Colection = function () {
  return (
    <div>
      <div className="row ">
        <div className="col-md-7">
          <div className="item_info">
            <h2>
              Elevate Online Success With AtoZee Solutions: Your Global Digital
              Marketing Ally
            </h2>
            <p>
              Launching your business online? Ignite global growth with AtoZee
              Solutions, the foremost digital marketing agency serving
              businesses worldwide. Our top-tier digital marketing experts
              ensure a swift boost in Google rankings and across all search
              preferences. Our results-driven digital marketing approach propels
              your business across digital platforms, offering a comprehensive
              mix of services, including Search Engine Optimization, Social
              Media Marketing, Google My Business Listing, and more. Opt for
              excellence to achieve remarkable global results.
            </p>
            <div className="spacer-40"></div>
          </div>
        </div>
        <div className="col-md-5 text-center">
          <img
            src="./img/services/digital-marketing3.gif"
            className="img-fluid img-rounded mb-sm-30"
            alt=""
          />
        </div>
      </div>

      {/* Pricing Section */}
      {/* <section>
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <span>PRICING PLANS</span>
              <h2>The Best Solutions For Our Clients</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className="col-lg-12">
            <WebPricing />
          </div>
        </div>
      </section> */}

      <section className="pb-0">
        <div className="col-md-12">
          <div className="item_info">
            <h2>AtoZee Solutions – Your Gateway To Global Business Success</h2>
            <p>
              Optimize your business's digital presence across all platforms for
              steady global growth. Our Melbourne-based digital marketing
              experts possess the skills to boost your website’s digital
              presence, driving growth through optimization and lead generation.
              Start selling quickly and provide superior services through your
              online platform. AtoZee Solutions, your trusted global digital
              marketing agency, offers unparalleled service quality for all
              requirements. Call us or fill out our form to explore digital
              marketing services and pricing plans.
            </p>
            <p>
              Searching for a trustworthy global digital marketing agency?
              You've found it. AtoZee Solutions is the leading digital marketing
              agency offering businesses worldwide the perfect opportunity for
              steady growth. We specialize in organic growth through the best
              SEO strategy and execute paid digital marketing campaigns with our
              social media marketing expertise. Our services are available for
              your business year-round. Call us today to embark on the journey
              to global success.
            </p>
            <div className="spacer-40"></div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Colection;
