import React from "react";
import WebPricing from "./webPricing";

const Colection = function () {
  return (
    <div>
      <div className="row ">
        <div className="col-md-7">
          <div className="item_info">
            <h2>
              Elevate Online Success With AtoZee Solutions: Your Global Digital
              Marketing Ally
            </h2>
            <p>
              Launching your business online? Ignite global growth with AtoZee
              Solutions, the foremost digital marketing agency serving
              businesses worldwide. Our top-tier digital marketing experts
              ensure a swift boost in Google rankings and across all search
              preferences. Our results-driven digital marketing approach propels
              your business across digital platforms, offering a comprehensive
              mix of services, including Search Engine Optimization, Social
              Media Marketing, Google My Business Listing, and more. Opt for
              excellence to achieve remarkable global results.
            </p>
            <div className="spacer-40"></div>
          </div>
        </div>
        <div className="col-md-5 text-center">
          <img
            src="./img/services/digital-marketing3.gif"
            className="img-fluid img-rounded mb-sm-30"
            alt=""
          />
        </div>
      </div>

      {/* Pricing Section */}
      {/* <section>
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <span>PRICING PLANS</span>
              <h2>The Best Solutions For Our Clients</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className="col-lg-12">
            <WebPricing />
          </div>
        </div>
      </section> */}

      <section className="pb-0">
        <div className="col-md-12">
          <div className="item_info">
            <h2>Your Online Presence With Strategic Digital Marketing</h2>
            <p>
              Selling products online revolves around reaching your target
              audience. AtoZee Solutions offers high-quality digital marketing
              services globally, boosting your business online. Showcase your
              product, service, or business to your target audience to enhance
              revenue generation. Masters of SEO, Social Media Marketing, and
              all digital marketing areas, we ensure swift growth for businesses
              across industries. Quick growth is guaranteed with our leading
              digital marketing expertise, enabling future business success.
              AtoZee Solutions delivers high-quality Google My Business Listing
              services globally for businesses of all sizes. Whether you're a
              startup or an enterprise, our fully optimized Google My Business
              Listing service guarantees swift sales and visits to your store or
              outlet worldwide. Additionally, we extend high-quality Google My
              Business Listing and Digital Marketing services to businesses and
              brands across the globe.
            </p>
            <p>
              Engage the finest global digital marketing team to give your
              business a stellar online start. Our digital marketing services
              cater to startups and established enterprises worldwide at
              affordable prices. Pay the right price for your global digital
              marketing services and witness rapid growth in any target market.
              We are the specialists you need for accelerated growth through
              organic and paid online marketing campaigns. Call us today to
              discover more.
            </p>
            <div className="spacer-40"></div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Colection;
