import React from "react";
import { Link } from "@reach/router";

const footer = () => (
  <footer className="footer-light">
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-6 col-xs-1">
          <div className=" container">
            <img
              alt="AtoZee Sols"
              className="f-logo d-1"
              height={"50px"}
              src="./img/logos/atozee.png"
            />

            <div className="spacer-10 mb-4"></div>
            <p>
              Empowering Growth: Your Digital Journey Starts Here! Seamless
              Website Designing & App Development with our Powerful Digital
              Marketing Solutions Tailored for Success.
            </p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-1">
          <div className=" container">
            <h5>Important Links</h5>
            <div className="spacer-10 mb-4"></div>
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-1">
          <div className=" container">
            <h5>Information</h5>

            <div className="spacer-10 mb-4"></div>
            <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-phone fa-lg"></i> + 447479397267
            </span>
            <br></br>
            <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-envelope fa-lg"></i> info@atozeesols.com
            </span>
            {/* newsletter code */}
            {/* <h5>Newsletter</h5>
            <p>
              Signup for our newsletter to get the latest news in your inbox.
            </p>
            <form
              action="#"
              className="row form-dark"
              id="form_subscribe"
              method="post"
              name="form_subscribe"
            >
              <div className="col text-center">
                <input
                  className="form-control"
                  id="txt_subscribe"
                  name="txt_subscribe"
                  placeholder="enter your email"
                  type="text"
                />
                <Link to="" id="btn-subscribe">
                  <i className="arrow_right bg-color-secondary"></i>
                </Link>
                <div className="clearfix"></div>
              </div>
            </form>
            <div className="spacer-10"></div>
            <small>Your email is safe with us. We don't spam.</small> */}

            <div className="subfooter">
              <div className="social-icons">
                <a
                  href="https://www.instagram.com/atozee.solutions/"
                  target="_blank"
                  rel="noreferrer"
                  aria-labelledby="instagram"
                >
                  <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-instagram fa-lg"></i>
                  </span>
                </a>
                <a
                  href="https://pk.linkedin.com/company/atozeesols"
                  target="_blank"
                  rel="noreferrer"
                  aria-labelledby="linkedin"
                >
                  <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-linkedin fa-lg"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="subfooter">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex">
              <div className="de-flex-col">
                <span onClick={() => window.open("", "_self")}>
                  <span className="copy">
                    Copyright &copy; 2024 - All Rights Reserved
                  </span>
                </span>
              </div>
              {/* <div className="de-flex-col">
                <div className="social-icons">
                  <a
                    href="https://www.instagram.com/atozee.solutions/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span onClick={() => window.open("", "_self")}>
                      <i className="fa fa-instagram fa-lg"></i>
                    </span>
                  </a>
                  <a
                    href="https://pk.linkedin.com/company/atozeesols"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span onClick={() => window.open("", "_self")}>
                      <i className="fa fa-linkedin fa-lg"></i>
                    </span>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
export default footer;
