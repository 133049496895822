import React from "react";
import WebPricing from "./webPricing";

const Colection = function () {
  return (
    <div>
      <div className="row ">
        <div className="col-md-7">
          <div className="item_info">
            <h2>Exceptional Mobile App Development</h2>
            <p>
              At AtoZee Solutions, we provide high-quality mobile app
              development services globally, tailoring solutions for diverse
              business ideas. From eCommerce apps to captivating business apps,
              our offerings are designed to provide intuitive solutions for
              clients worldwide. Our Android app development and iOS app
              development services are crafted with quick delivery turnaround,
              ensuring businesses across the globe receive
              performance-optimized, visually appealing apps.
            </p>
            <p>
              In search of a reliable global mobile app development partner?
              AtoZee Solutions is your go-to service provider. We are the
              leading iOS and Android app development service providers
              globally, offering secure apps for your brand and business. From
              initial design consultation to successful installation and
              implementation, we provide the full app development cycle globally
              for all requirements. Call us today or fill out our form to
              discover more, anytime and anywhere.
            </p>
            <div className="spacer-40"></div>
          </div>
        </div>
        <div className="col-md-5 text-center">
          <img
            src="./img/services/app-development.gif"
            className="img-fluid img-rounded mb-sm-30"
            alt=""
          />
        </div>
      </div>

      {/* Pricing Section */}
      {/* <section>
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <span>PRICING PLANS</span>
              <h2>The Best Solutions For Our Clients</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className="col-lg-12">
            <WebPricing />
          </div>
        </div>
      </section> */}

      <section className="pb-0">
        <div className="col-md-12">
          <div className="item_info">
            <h2>Android / IOS App Development For Global Businesses</h2>
            <p>
              Specializing in professional Android app development services for
              businesses worldwide, AtoZee Solutions enables you to showcase
              your business to the vast Android user base. Our developers offer
              attractive designs and highly optimized app platforms, ensuring
              your business gains quick success. Reach out to our Android app
              development team to give your business a strong start on the
              Android user platform. For businesses planning to launch on the
              Apple ecosystem, AtoZee Solutions provides the best iOS app
              development to capture the global market using iPhones, iPads, and
              other iOS devices. We specialize in high-quality iOS app
              development services for eCommerce and service-sector businesses
              globally. Hire our iOS app development team to present your
              business attractively on one of the biggest platforms. Our
              experienced team offers attractive app designs and fully optimized
              development for global success.
            </p>
            <p>
              In search of a reliable global mobile app development partner?
              AtoZee Solutions is your go-to service provider. We are the
              leading iOS and Android app development service providers
              globally, offering secure apps for your brand and business. From
              initial design consultation to successful installation and
              implementation, we provide the full app development cycle globally
              for all requirements. Call us today or fill out our form to
              discover more, anytime and anywhere.
            </p>
            <div className="spacer-40"></div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Colection;
