import React from "react";

const WhatsAppButton = () => {
  const phoneNumber = "+923012775034";  // Replace with your actual WhatsApp number
  const message = "Hello! I need some assistance regarding your services.";

  // Encode the phone number and message for the URL
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  const encodedMessage = encodeURIComponent(message);

  // WhatsApp API link
  const whatsappLink = `https://api.whatsapp.com/send?phone=${encodedPhoneNumber}&text=${encodedMessage}`;

  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button"
    >
      <i className="fa fa-whatsapp text-large col-white"></i>
      <span className="col-white">Let's Connect</span>
    </a>
  );
};

export default WhatsAppButton;
