import React from "react";
import WebPricing from "./webPricing";

const Colection = function () {
  return (
    <div>
      <div className="row ">
        <div className="col-md-7">
          <div className="item_info">
            <h2>
              Elevate Online Success With AtoZee Solutions: Your Global Digital
              Marketing Ally
            </h2>
            <p>
              Launching your business online? Ignite global growth with AtoZee
              Solutions, the foremost digital marketing agency serving
              businesses worldwide. Our top-tier digital marketing experts
              ensure a swift boost in Google rankings and across all search
              preferences. Our results-driven digital marketing approach propels
              your business across digital platforms, offering a comprehensive
              mix of services, including Search Engine Optimization, Social
              Media Marketing, Google My Business Listing, and more. Opt for
              excellence to achieve remarkable global results.
            </p>
            <div className="spacer-40"></div>
          </div>
        </div>
        <div className="col-md-5 text-center">
          <img
            src="./img/services/digital-marketing3.gif"
            className="img-fluid img-rounded mb-sm-30"
            alt=""
          />
        </div>
      </div>

      {/* Pricing Section */}
      {/* <section>
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <span>PRICING PLANS</span>
              <h2>The Best Solutions For Our Clients</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className="col-lg-12">
            <WebPricing />
          </div>
        </div>
      </section> */}

      <section className="pb-0">
        <div className="col-md-12">
          <div className="item_info">
            <h2>
              Amplify Your Online Presence With Strategic Digital Marketing
            </h2>
            <p>
              Selling products online is all about reaching your target
              audience. AtoZee Solutions offers top-notch global digital
              marketing services that elevate your business online. Showcase
              your product, service, or business to your target audience to
              enhance revenue generation. As masters of SEO, Social Media
              Marketing, and all digital marketing areas, we ensure a rapid
              boost for businesses from all industries. Quick growth is
              guaranteed with our leading digital marketing expertise, enabling
              future business success.
            </p>
            <div className="spacer-40"></div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Colection;
