import React from "react";
import WebPricing from "./webPricing";

const Colection = function () {
  return (
    <div>
      <div className="row ">
        <div className="col-md-7">
          <div className="item_info">
            <h2>Exceptional Website Development And Design</h2>
            <p>
              AtoZee Solutions stands tall as a global leader in crafting
              visually captivating, performance-optimized websites and
              delivering top-tier digital marketing services worldwide. Launch
              your business onto the global stage with our visually appealing,
              sales-optimized website designs. We specialize in creating
              industry-focused websites that cater to diverse business needs.
              Our expertise spans various platforms, including WordPress,
              Shopify, Wix, PHP, and more.
            </p>
            <p>
              Selling more online requires the right business website platform.
              AtoZee Solutions offers high-quality global e-commerce website
              development services. Our attractive and intuitive e-commerce
              store designs cater to brands and sellers across all industries.
              SEO-optimized e-commerce website development services are
              available worldwide. Contact us today to learn more about how we
              can boost your online sales.
            </p>
            <div className="spacer-40"></div>
          </div>
        </div>
        <div className="col-md-5 text-center">
          <img
            src="./img/services/website.png"
            className="img-fluid img-rounded mb-sm-30"
            alt=""
          />
        </div>
      </div>

      {/* Pricing Section */}
      {/* <section>
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <span>PRICING PLANS</span>
              <h2>The Best Solutions For Our Clients</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className="col-lg-12">
            <WebPricing />
          </div>
        </div>
      </section> */}

      <section className="pb-0">
        <div className="col-md-12">
          <div className="item_info">
            <h2>
              WordPress, WooCommerce And Shopify Store Development For A Global
              Audience
            </h2>
            <p>
              As a leading global website development service provider, AtoZee
              Solutions offers intuitive WordPress website development services.
              Choose from a variety of website themes and designs to create a
              perfect platform for your global business. Our experts ensure
              secure WordPress website development tailored to your needs.
              AtoZee Solutions provides high-quality Shopify store development
              services worldwide. Our Shopify experts specialize in highly
              optimized and sales-focused website designs. Launch your business
              online successfully with our attractive and intuitive Shopify
              stores, all globally SEO-optimized.
            </p>
            <p>
              AtoZee Solutions stands out as one of the best website development
              service providers globally. Our advanced website designs empower
              brands and businesses for quick success in any target market. From
              customer-focused e-commerce websites to backend business websites,
              we provide website solutions for brands worldwide. Streamline your
              everyday business operations with functionality-driven websites
              that enhance productivity.
            </p>
            <div className="spacer-40"></div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Colection;
