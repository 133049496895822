import React from "react";

const AboutContent = () => (
  <section className="container no-bottom p-0">
    <div className="row">
        
      <div className="col-md-7">
        <div className="item_info">
          <h2>
            Bring Your Business Online With An Optimized Website/App Platform
          </h2>
          <p>
            Hello there! We are AtoZee Solutions, and we are here to make your
            digital dreams come true. Imagine us as your friendly guides in the
            vast world of the internet. Our journey started with a simple idea –
            to help you succeed in the online world without any stress. We are
            not just a company; we are your pals, ready to embark on this
            digital adventure with you. Our team is a bunch of tech-loving
            enthusiasts who enjoy creating amazing things for you. We speak the
            language of simplicity because we believe in making the digital
            world easy for everyone. Together, let's explore the exciting
            possibilities and make your online presence shine.
          </p>
          <div className="spacer-40"></div>
        </div>
      </div>
      <div className="col-md-5 text-center">
        <img
          src="./img/services/about-us.webp"
          className="img-fluid img-rounded mb-sm-30"
          alt="About-atozeeSols"
          loading="lazy"
        />
      </div>
    </div>
  </section>
);
export default AboutContent;
