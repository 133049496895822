import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Reveal from "react-awesome-reveal";
// import { keyframes } from "@emotion/react";

// const fadeInUp = keyframes`
//   0% {
//     opacity: 0;
//     -webkit-transform: translateY(40px);
//     transform: translateY(40px);
//   }
//   100% {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//   }
// `;
// const inline = keyframes`
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
//   .d-inline{
//     display: inline-block;
//    }
// `;

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return <div {...props}></div>;
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      infinite: true,
      autoplay: true, // Add autoplay setting
      // // autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
      // infinite: false,
      // // autoplay: true, // Add autoplay setting
      // // autoplaySpeed: 2000,
      // speed: 500,
      // slidesToShow: 3,
      // slidesToScroll: 1,
      // initialSlide: 0,
      // responsive: [
      //   {
      //     breakpoint: 100,
      //     settings: {
      //       slidesToShow: 3,
      //       slidesToScroll: 1,
      //       infinite: true,
      //     },
      //   },
      //   {
      //     breakpoint: 1600,
      //     settings: {
      //       slidesToShow: 3,
      //       slidesToScroll: 1,
      //       infinite: true,
      //     },
      //   },
      //   {
      //     breakpoint: 1024,
      //     settings: {
      //       slidesToShow: 3,
      //       slidesToScroll: 1,
      //       infinite: true,
      //     },
      //   },
      //   {
      //     breakpoint: 600,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 1,
      //       initialSlide: 2,
      //     },
      //   },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       slidesToShow: 3,
      //       slidesToScroll: 1,
      //       dots: true,
      //     },
      //   },
      // ],
    };
    return (
      <div className="nft">
        <Slider {...settings}>
          <CustomSlide className="itm" index={1}>
            <div className="nft_coll border-0">
              <div className="nft_wrap">
                <span>
                  <img
                    src="./img/clients/atozeesols-client-fortknox.png"
                    className="lazy img-fluid"
                    alt="AtoZeeSols-client-Fortknox"
                    loading="lazy"
                  />
                </span>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={2}>
            <div className="nft_coll border-0">
              <div className="nft_wrap">
                <span>
                  <img
                    src="./img/clients/atozeesols-client-pns.png"
                    className="lazy img-fluid"
                    alt="AtoZeeSols-client-pns"
                    loading="lazy"
                  />
                </span>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={3}>
            <div className="nft_coll border-0">
              <div className="nft_wrap">
                <span>
                  <img
                    src="./img/clients/atozeesols-client-mangoarts.png"
                    className="lazy img-fluid"
                    alt="AtoZeeSols-client-Mangoarts"
                    loading="lazy"
                  />
                </span>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={4}>
            <div className="nft_coll border-0">
              <div className="nft_wrap">
                <span>
                  <img
                    src="./img/clients/atozeesols-client-Netopz.png"
                    className="lazy img-fluid"
                    alt="AtoZeeSols-client-Netopz"
                    loading="lazy"
                  />
                </span>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={5}>
            <div className="nft_coll border-0">
              <div className="nft_wrap">
                <span>
                  <img
                    src="./img/clients/atozeesols-client-Fin-soul.jpg"
                    className="lazy img-fluid "
                    alt="AtoZeeSols-client-Fin-soul"
                    // style={{ objectFit: 'cover'}}
                  />
                </span>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={6}>
            <div className="nft_coll border-0">
              <div className="nft_wrap">
                <span>
                  <img
                    src="./img/clients/atozeesols-clients-wyndham.png"
                    className="lazy img-fluid "
                    alt="AtoZeeSols-client-wyndham"
                    loading="lazy"
                  />
                </span>
              </div>
            </div>
          </CustomSlide>
          <CustomSlide className="itm" index={7}>
            <div className="nft_coll border-0">
              <div className="nft_wrap">
                <span>
                  <img
                    src="./img/clients/atozeesols-client-sky.png"
                    className="lazy img-fluid"
                    alt="AtoZeeSols-client-Sky"
                    loading="lazy"
                  />
                </span>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={8}>
            <div className="nft_coll border-0">
              <div className="nft_wrap">
                <span>
                  <img
                    src="./img/clients/atozeesols-client-MuscatAuditing.png"
                    className="lazy img-fluid "
                    alt="AtoZeeSols-client-MuscatAuditing"
                    loading="lazy"
                  />
                </span>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={9}>
            <div className="nft_coll border-0">
              <div className="nft_wrap">
                <span>
                  <img
                    src="./img/clients/atozeeSols-client-mfd.png"
                    className="lazy img-fluid text-center"
                    alt="AtoZeeSols-client-MFD"
                    loading="lazy"
                  />
                </span>
              </div>
            </div>
          </CustomSlide>
        </Slider>
      </div>
    );
  }
}
