import React from "react";
import Footer from "../../components/footer";
import EmailMarketingContent from "../../components/emailMarketingContent";

const emailMarketing = () => (
  <div>

    <section
      className="jumbotron breadcumb no-bg"
    >
      <video autoPlay muted loop
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
        
        controls={false}
      >
        <source src="./img/mp4/header.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="mainbreadcumb bg-custom">
        <div className="container">
          <div className="row m-10-hor">
            <div className="col-12 ">
              <h1 className="pb-4">Email Marketing</h1>
              <p>Transform your ideas into a solid business product with our bespoke web solutions and expertise in Fintech, Healthcare, Construction, and other industries</p>
            </div>
          </div>
        </div>
      </div>
      
      <nav aria-label="breadcrumb">
                <ol className="breadcrumb  p-2">
                  <li className="breadcrumb-item text-white">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item  text-white" aria-current="true">
                    Marketing
                  </li>
                  <li className="breadcrumb-item active  text-white" aria-current="true">
                  Email Marketing
                  </li>
                </ol>
              </nav>
    </section>

    <section className="container">
      <EmailMarketingContent />
    </section>

    <Footer />
  </div>
);
export default emailMarketing;
