import React from "react";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import { Link } from "@reach/router";
import AboutContent from '../components/AboutContent';
import Services from "../components/Services";

const GlobalStyles = createGlobalStyle`
  // header#myHeader.navbar.sticky.white {
  //   background: #403f83;
  //   border-bottom: solid 1px #403f83;
  // }
  // header#myHeader.navbar .search #quick_search{
  //   color: #fff;
  //   background: rgba(255, 255, 255, .1);
  // }
  // header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
  //   color: #fff;
  // }
  // header#myHeader .dropdown-toggle::after{
  //   color: rgba(255, 255, 255, .5);
  // }
  // header#myHeader .logo .d-block{
  //   display: none !important;
  // }
  // header#myHeader .logo .d-none{
  //   display: block !important;
  // }
  // @media only screen and (max-width: 1199px) {
  //   .navbar{
  //     background: #403f83;
  //   }
  //   .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
  //     background: #fff;
  //   }
  //   .item-dropdown .dropdown a{
  //     color: #fff !important;
  //   }
  // }
`;

const about = () => (
  <div>
    <GlobalStyles />

    <section
      className="jumbotron breadcumb no-bg"
    >
      <video autoPlay muted loop
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      >
        <source src="./img/mp4/AtozeeSols-service-HUB-1.mp4" type="video/mp4" />
        {/* Add additional source elements for different video formats if needed */}
        Your browser does not support the video tag.
      </video>
      <div className="mainbreadcumb bg-custom">
        <div className="container">
          <div className="row m-10-hor">
            <div className="col-12 ">
              <h1 className="pb-4">About AtoZee Tech Solutions</h1>
              <p>Transform your ideas into a solid business product with our bespoke web solutions and expertise in Fintech, Healthcare, Construction, and other industries</p>
            </div>
          </div>
        </div>
      </div>
      
      <nav aria-label="breadcrumb">
                <ol className="breadcrumb  p-2">
                  <li className="breadcrumb-item text-white">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active  text-white" aria-current={"true"}>
                    About Us
                  </li>
                </ol>
              </nav>
    </section>

    <section className="container">
      
    <AboutContent />
    </section>

    <section className="container no-bottom p-0">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h2>Our Services</h2>
            <div className="small-border"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <Services />
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
export default about;
