import React from "react";
import Footer from "../../components/footer";
import AdsCampaignContent from "../../components/adsCampaignContent";

const adsCampaign = () => (
  <div>
    <section className="jumbotron breadcumb no-bg">
      <video
        autoPlay
        muted
        loop
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
        
        controls={false}
      >
        <source
          src="./img/mp4/atozeeSols-hero-2.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div className="mainbreadcumb bg-custom">
        <div className="container">
          <div className="row m-10-hor">
            <div className="col-12 ">
              <h1 className="pb-4">Paid Ads</h1>
              <p>
                {/* Use our extensive expertise in creating prototypes, MVPs, PoCs, and other digital solutions for startups to enable your business */}
              </p>
            </div>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <ol className="breadcrumb  p-2">
          <li className="breadcrumb-item text-white">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item  text-white" aria-current="true">
            Marketing
          </li>
          <li
            className="breadcrumb-item active  text-white"
            aria-current="true"
          >
            Paid Ads
          </li>
        </ol>
      </nav>
    </section>

    <section className="container">
      <AdsCampaignContent />
    </section>

    <Footer />
  </div>
);
export default adsCampaign;
