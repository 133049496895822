import React, { useEffect, useState } from "react";
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import { Link } from "@reach/router";
import useOnclickOutside from "react-cool-onclickoutside";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? "active" : "non-active",
      };
    }}
  />
);

const Header = function () {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  // const handleBtnClick = () => {
  //   setOpenMenu(!openMenu);
  // };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
  };
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2);
  };
  // const handleBtnClick3 = () => {
  //   setOpenMenu3(!openMenu3);
  // };
  const closeMenu = () => {
    setOpenMenu(false);
  };
  const closeMenu1 = () => {
    setOpenMenu1(false);
  };
  const closeMenu2 = () => {
    setOpenMenu2(false);
  };
  const closeMenu3 = () => {
    setOpenMenu3(false);
  };
  // const ref = useOnclickOutside(() => {
  //   closeMenu();
  // });
  const ref1 = useOnclickOutside(() => {
    closeMenu1();
  });
  const ref2 = useOnclickOutside(() => {
    closeMenu2();
  });
  // const ref3 = useOnclickOutside(() => {
  //   closeMenu3();
  // });

  const [showmenu, btn_icon] = useState(false);
  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
      if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);
  return (
    <header id="myHeader" className="navbar bg-white white">
      <div className="container">
        <div className="row w-100-nav">
          <div className="logo px-0">
            <div className="navbar-title navbar-item">
              <NavLink to="/">
                <img
                  src="./img/logos/atozee.png"
                  className=" d-block"
                  height={"30px"}
                  width={"auto"}
                  alt="#"
                />
              </NavLink>
            </div>
          </div>

          {/* <div className="search">
            <input
              id="quick_search"
              className="xs-hide"
              name="quick_search"
              placeholder="search item here..."
              type="text"
            />
          </div> */}

          <BreakpointProvider>
            <Breakpoint l down>
              {showmenu && (
                <div className="menu">
                  <div className="navbar-item">
                    <NavLink to="/" onClick={() => btn_icon(!showmenu)}>
                      Home
                    </NavLink>

                    {
                      // <div ref={ref}>
                      //   <div
                      //     className="dropdown-custom dropdown-toggle btn"
                      //     onClick={handleBtnClick}
                      //   >
                      //     Home
                      //   </div>
                      //   {openMenu && (
                      //     <div className="item-dropdown">
                      //       <div className="dropdown" onClick={closeMenu}>
                      //         <NavLink
                      //           to=" "
                      //           onClick={() =>
                      //             window.open(
                      //               "http://gigaland.grey.on3-step.com",
                      //               "_self"
                      //             )
                      //           }
                      //         >
                      //           New Grey Scheme
                      //         </NavLink>
                      //         <NavLink
                      //           to=" "
                      //           onClick={() =>
                      //             window.open(
                      //               "http://gigaland.retro.on3-step.com",
                      //               "_self"
                      //             )
                      //           }
                      //         >
                      //           New Retro Scheme
                      //         </NavLink>
                      //         <NavLink
                      //           to="/"
                      //           onClick={() => btn_icon(!showmenu)}
                      //         >
                      //           Homepage
                      //         </NavLink>
                      //         <NavLink
                      //           to="/home1"
                      //           onClick={() => btn_icon(!showmenu)}
                      //         >
                      //           Homepage 1
                      //         </NavLink>
                      //         <NavLink
                      //           to="/home2"
                      //           onClick={() => btn_icon(!showmenu)}
                      //         >
                      //           Homepage 2
                      //         </NavLink>
                      //         <NavLink
                      //           to="/home3"
                      //           onClick={() => btn_icon(!showmenu)}
                      //         >
                      //           Homepage 3
                      //         </NavLink>
                      //       </div>
                      //     </div>
                      //   )}
                      // </div>
                    }
                  </div>
                <div className="navbar-item">
                  <div ref={ref1}>
                    <div
                      className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick1}
                      onMouseLeave={closeMenu1}
                    >
                      Design & Develop
                      <span className="lines"></span>
                      {openMenu1 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu1}>
                            <NavLink to="/website" onClick={() => btn_icon(!showmenu)}>Website Development</NavLink>
                            <NavLink to="/mobileApp" onClick={() => btn_icon(!showmenu)}>App Development</NavLink>
                            {/* <NavLink to="/customSoftware">Saas Development</NavLink> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <div ref={ref2}>
                    <div
                      className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick2}
                      onMouseLeave={closeMenu2}
                    >
                      Digital Marketing
                      <span className="lines"></span>
                      {openMenu2 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu2}>
                            <NavLink to="/adsCampaign" onClick={() => btn_icon(!showmenu)}>Paid Ads</NavLink>
                            <NavLink to="/socialMediaMarketing" onClick={() => btn_icon(!showmenu)}>
                              Social Media Marketing
                            </NavLink>
                            <NavLink to="/emailMarketing" onClick={() => btn_icon(!showmenu)}>
                              Email Marketing
                            </NavLink>
                            <NavLink to="/seo" onClick={() => btn_icon(!showmenu)}>
                              Search Engine Optimization
                            </NavLink>
                            <NavLink to="/contentWriting" onClick={() => btn_icon(!showmenu)}>
                              Content Writing
                            </NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                  <div className="navbar-item">
                    <NavLink to="/about" onClick={() => btn_icon(!showmenu)}>
                      About
                    </NavLink>
                  </div>
                </div>
              )}
            </Breakpoint>

            <Breakpoint xl>
              <div className="menu">
                <div className="navbar-item">
                  <NavLink to="/" onClick={() => btn_icon(!showmenu)}>
                    Home
                    <span className="lines"></span>
                  </NavLink>
                  {/* <div ref={ref}>
                    <div
                      className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick}
                      onMouseLeave={closeMenu}
                    >
                      Home
                      <span className="lines"></span>
                      {openMenu && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu}>
                            <NavLink
                              to=" "
                              onClick={() =>
                                window.open(
                                  "http://gigaland.grey.on3-step.com",
                                  "_self"
                                )
                              }
                            >
                              New Grey Scheme
                            </NavLink>
                            <NavLink
                              to=" "
                              onClick={() =>
                                window.open(
                                  "http://gigaland.retro.on3-step.com",
                                  "_self"
                                )
                              }
                            >
                              New Retro Scheme
                            </NavLink>
                            <NavLink to="/">Homepage</NavLink>
                            <NavLink to="/home1">Homepage 1</NavLink>
                            <NavLink to="/home2">Homepage 2</NavLink>
                            <NavLink to="/home3">Homepage 3</NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
                <div className="navbar-item">
                  <div ref={ref1}>
                    <div
                      className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick1}
                      onMouseLeave={closeMenu1}
                    >
                      Design & Develop
                      <span className="lines"></span>
                      {openMenu1 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu1}>
                            <NavLink to="/website">Website Development</NavLink>
                            <NavLink to="/mobileApp">App Development</NavLink>
                            {/* <NavLink to="/customSoftware">Saas Development</NavLink> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <div ref={ref2}>
                    <div
                      className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick2}
                      onMouseLeave={closeMenu2}
                    >
                      Digital Marketing
                      <span className="lines"></span>
                      {openMenu2 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu2}>
                            <NavLink to="/adsCampaign">Paid Ads</NavLink>
                            <NavLink to="/socialMediaMarketing">
                              Social Media Marketing
                            </NavLink>
                            <NavLink to="/emailMarketing">
                              Email Marketing
                            </NavLink>
                            <NavLink to="/seo">
                              Search Engine Optimization
                            </NavLink>
                            <NavLink to="/contentWriting">
                              Content Writing
                            </NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <NavLink to="/about">
                    About
                    <span className="lines"></span>
                  </NavLink>
                </div>
              </div>
            </Breakpoint>
          </BreakpointProvider>

          <div className="mainside">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://web.whatsapp.com/send?phone=923012775034&text=Hello! I need some assistance regarding your services."
              className="btn-gradient "
              aria-labelledby="whatsapp"
            >
              <i className="text-lg-start fa fa-whatsapp m-2"></i> Contact Now
            </a>
          </div>
        </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)} 
              aria-label="icon">
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
    </header>
  );
};
export default Header;
