import React, { lazy } from "react";
import { Helmet } from "react-helmet";
import SliderMainParticle from "../components/SliderMainParticle";
// const SliderMainParticle = lazy(() => import("../components/SliderMainParticle"));
import AboutContent from "../components/AboutContent";
// const AboutContent = lazy(() => import("../components/AboutContent"));
import Services from "../components/Services";


// import CarouselProject from "../components/CarouselProject";
import CarouselClient from "../components/CarouselClient";
// import Catgor from "../components/Catgor";
import Footer from "../components/footer";
// import Particle from "../components/Particle";

const home = () => (
  
  <div>
    <Helmet>
      <meta
        property="og:title"
        content="AtoZee Tech Solutions - Your Digital Companion"
      />
      <meta
        property="og:description"
        content="Discover the digital world with AtoZee Tech Solutions. Supporting businesses online since 4+ years, we offer expert solutions in marketing and development."
      />
      <meta property="og:image" content="%PUBLIC_URL%/favicon.png" />

      <title>AtoZee Tech Solutions - Your Digital Companion</title>
      <meta
        name="description"
        content="Discover the digital world with AtoZee Tech Solutions. Supporting businesses online since 4+ years, we offer expert solutions in marketing and development."
      />
      <meta
        name="keywords"
        content="Digital Partner, Marketing, Development, Online Success, AtoZee Tech Solutions"
      />
    </Helmet>
    <section
      className="jumbotron breadcumb bg-custom h-vh"
      loading="eager"
      style={{
        backgroundImage: `url(${"./img/background/banner-4.avif"})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: "100%",
        height: "auto",
      }}
    >
      {/* <img
  srcset="./img/background/banner-4-400w.avif 400w, ./img/background/banner-4-600w.avif 600w, ./img/background/banner-4-800w.avif 800w, ./img/background/banner-4-1000w.avif 1000w, ./img/background/banner-4-1200w.avif 1200w, ./img/background/banner-4-1600w.avif 1600w"
  sizes="(max-width: 400px) 400px, (max-width: 600px) 600px, (max-width: 800px) 800px, (max-width: 1000px) 1000px, (max-width: 1200px) 1200px, (min-width: 1201px) 1600px"
  src="./img/background/banner-4.jpg"
  alt="AtoZeeSols-Banner"
  width="100%"
  height="auto"
/> */}
      {/* <Particle /> */}
      <div class="m-5 br-15">
        <SliderMainParticle />
        {/* <CarouselPartner /> */}
      </div>
    </section>

    <section className="container no-bottom">
      <AboutContent />
    </section>

    <section className="container no-bottom">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h3>Our Services</h3>
            <div className="small-border"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <Services />
        </div>
      </div>
    </section>

    {/* <section className="container no-bottom">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h2>Recent Projects</h2>
            <div className="small-border"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <CarouselProject />
        </div>
      </div>
    </section> */}

    <section className="container no-bottom">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h2>Our Clients</h2>
            <div className="small-border"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <CarouselClient />
        </div>
      </div>
    </section>
    <Footer />
  </div>
);
export default home;
