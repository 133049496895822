import React from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
// const inline = keyframes`
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
//   .d-inline{
//     display: inline-block;
//    }
// `;

const slidermainparticle = () => (
  <div className="">
    <div className="row align-items-center">
      <div className="col-md-6">
        <div className="spacer-single"></div>
        <div className="spacer-double"></div>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={300}
          duration={900}
          triggerOnce
        >
          <h1 className="col-white">
            Build, Share, Gather - Your Easy Digital Adventure
          </h1>
        </Reveal>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={600}
          duration={900}
          triggerOnce
        >
          <p className="lead col-white">
            AtoZee Tech Solutions: Crafting Success with Apps, Websites, and
            Custom Software. Elevate Your Business through Expert Digital
            Marketing, SEO, and Google Ads – Where Growth Meets Simplicity.
          </p>
        </Reveal>
        <div className="spacer-10"></div>
        {/* <Reveal
          className="onStep d-inline"
          keyframes={inline}
          delay={800}
          duration={900}
          triggerOnce
        >
          <div className="row">
            <span
              onClick={() => window.open("#", "_self")}
              className="btn-main wow fadeInUp animated m-1"
            >
              Explore
            </span>
            <span
              onClick={() => window.open("#", "_self")}
              className="btn-main inline white lead m-1"
            >
              Create
            </span>
            <div className="mb-sm-30"></div>
          </div>
        </Reveal> */}
      </div>
    </div>
  </div>
);
export default slidermainparticle;
