import React from "react";
import Footer from "../../components/footer";
import CustomSoftwareContent from "../../components/customSoftwareContent";

const customSoftware = () => (
  <div>

    <section
      className="jumbotron breadcumb no-bg"
      // style={{ backgroundImage: `url(${"./img/background/5.jpg"})` }}
    >
      <video autoPlay muted loop
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
        
        controls={false}
      >
        <source src="./img/mp4/atozeeSols-desiging.mp4" type="video/mp4" />
        {/* Add additional source elements for different video formats if needed */}
        Your browser does not support the video tag.
      </video>
      <div className="mainbreadcumb bg-custom">
        <div className="container">
          <div className="row m-10-hor">
            <div className="col-12 ">
              <h1 className="pb-4">Bespoke Business Softwares</h1>
              <p>Transform your ideas into a solid business product with our bespoke web solutions and expertise in Fintech, Healthcare, Construction, and other industries</p>
            </div>
          </div>
        </div>
      </div>
      
      <nav aria-label="breadcrumb">
                <ol className="breadcrumb  p-2">
                  <li className="breadcrumb-item text-white">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item  text-white" aria-current={"true"}>
                    Design & Develop
                  </li>
                  <li className="breadcrumb-item active  text-white" aria-current={"true"}>
                    Custom Software Development
                  </li>
                </ol>
              </nav>
    </section>

    <section className="container">
      <CustomSoftwareContent />
    </section>

    <Footer />
  </div>
);
export default customSoftware;
